<template>
  <el-container>
    <el-container>
      <!-- 右侧栏 -->
      <el-aside :class="[isCollapse ? 'el-aside_on' : '']">
        <div class="logo">
          <img :src="content[1].value" alt="">
        </div>
        <el-row class="tac">
          <el-col>

            <el-menu :default-active="isActiveMenu == '1' ? isActiveMenu : activeMenu" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" background-color="#d8e4fb" text-color="#666" active-text-color="#fff" :collapse="isCollapse" :collapse-transition="false" :unique-opened="true">

              <fragment v-for="(item, index) in menuList" :key="index">

                <fragment v-if="!item.children">
                  <el-menu-item :index="(index + 1).toString()" @click="toSubMenu(item.path, item, (index + 1))">
                    <!-- <i class="iconfont" :class="item.icon" style="font-size: 20px; margin-right: 10px;"></i> -->
                    <span slot="title">{{ item.name }}</span>
                  </el-menu-item>
                </fragment>

                <fragment v-else>
                  <el-submenu :index="(index + 1).toString()">
                    <template slot="title">
                      <span v-show="!isCollapse">{{ item.name }}</span>
                    </template>
                    <el-menu-item-group>
                      <div style="width: 100%; display: inline-block;" v-for="(iem, idx) in item.children" :key="idx">
                        <el-menu-item :index="(index + 1).toString() + '-' + (idx + 1).toString()" @click="toPage(iem.path, item, ((index + 1) + '-' + (idx + 1)))" v-if="!iem.children">
                          <span></span>{{ iem.name }}
                        </el-menu-item>
                        <el-submenu v-else :index="(index + 1).toString() + '-' + (idx + 1).toString()">
                          <template slot="title">
                            {{ iem.name }}
                          </template>
                          <div style="width: 100%; display: inline-block;" v-for="(i, grandChildIndex) in iem.children" :key="grandChildIndex" @click="toPage(i.path, item, ((index + 1) + '-' + (idx + 1) + '-' + (grandChildIndex + 1)))">
                            <el-menu-item :index="(index + 1).toString() + '-' + (idx + 1).toString() + '-' + (grandChildIndex + 1).toString()">
                              <span style="margin-left:30px;"></span>{{ i.name }}
                            </el-menu-item>
                          </div>
                        </el-submenu>
                      </div>

                    </el-menu-item-group>
                  </el-submenu>
                </fragment>

              </fragment>

            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <!-- 右侧栏 -->
      <el-container>
        <el-header>
          <Header :title="content[2].value"></Header>
        </el-header>
        <!-- 头部 -->
        <el-main>
          <router-view />
        </el-main>
        <!-- 主体 -->
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header.vue";
import { getConfig } from "@/api/home";
import { mapState } from "vuex";
export default {
  name: "home",
  components: {
    Header,
  },
  data() {
    return {
      activeMenu: "1", //菜单栏选中索引值
      content: null,
      // menuList: [
      //   {
      //     path: "/home",
      //     name: "概览",
      //     icon: "icon-shouye",
      //   },
      //   {
      //     path: "/merchant",
      //     name: "商户管理",
      //     icon: "icon-shouye",
      //     children: [
      //       {
      //         path: "/merchant/storeReview",
      //         name: "商户审核",
      //         icon: "icon-dingdanliebiao",
      //       },
      //       {
      //         path: "/merchant/categories",
      //         name: "主营类目",
      //         icon: "icon-dingdanliebiao",
      //       }
      //     ]
      //   },
      //   {
      //     path: "/user",
      //     name: "用户管理",
      //     icon: "icon-kehu",
      //     children: [
      //       {
      //         path: "/user/index",
      //         name: "用户列表",
      //         icon: "icon-dingdanliebiao",
      //       },
      //       {
      //         path: "/user/levelIndex",
      //         name: "等级设置",
      //         icon: "icon-dingdanliebiao",
      //       }
      //     ]
      //   },
      //   {
      //     path: "/commodity",
      //     name: "商品管理",
      //     icon: "icon-31quanbushangpin",
      //     children: [
      //       {
      //         path: "/commodity/cattle",
      //         name: "礼包商品列表",
      //         icon: "icon-shangpinduibi",
      //       },
      //       {
      //         path: "/commodity/cattleindex",
      //         name: "商品列表",
      //         icon: "icon-shangpinduibi",
      //       },
      //       {
      //         path: "/commodity/shopClass",
      //         name: "商品类目",
      //         icon: "icon-shangpinduibi",
      //       }
      //     ]
      //   },
      //   {
      //     path: "/order",
      //     name: "订单管理",
      //     icon: "icon-dingdan",
      //     children: [
      //       {
      //         path: "/order/platformList",
      //         name: "平台分销订单",
      //         icon: "icon-dingdanliebiao",
      //       },
      //       {
      //         path: "/order/merchantList",
      //         name: "商户分销订单",
      //         icon: "icon-dingdanliebiao",
      //       },
      //       {
      //         path: "/order/storeOrder",
      //         name: "门店自营订单",
      //         icon: "icon-wj-shd",
      //       },
      //       {
      //         path: "/order/orderList",
      //         name: "合伙人订单",
      //         icon: "icon-wj-shd",
      //       }
      //     ]
      //   },
      //   {
      //     path: "/after",
      //     name: "售后管理",
      //     icon: "icon-dingdan",
      //     children: [
      //       {
      //         path: "/after/afterProvidedOrder",
      //         name: "平台分销订单",
      //         icon: "icon-dingdanliebiao",
      //       },
      //       {
      //         path: "/after/afterMerchantOrder",
      //         name: "商户分销订单",
      //         icon: "icon-dingdanliebiao",
      //       },
      //       {
      //         path: "/after/afterStoreOrder",
      //         name: "门店自营订单",
      //         icon: "icon-wj-shd",
      //       }
      //     ]
      //   },
      //   // {
      //   //   path: "/financial",
      //   //   name: "财务管理",
      //   //   icon: "icon-shezhi",
      //   //   children:[
      //   //     {
      //   //       path: "/financial/bonusStatistics",
      //   //       name: "分红池统计",
      //   //       icon: "icon-changyongxinxi",
      //   //     },
      //   //     {
      //   //       path: "/financial/revenueRecords",
      //   //       name: "收益转入记录",
      //   //       icon: "icon-changyongxinxi",
      //   //     },
      //   //     {
      //   //       path: "/financial/moneyDetails",
      //   //       name: "佣金明细",
      //   //       icon: "icon-changyongxinxi",
      //   //     },
      //   //   ]
      //   // },
      //   // {
      //   //   path: "/courseManagement",
      //   //   name: "课程管理",
      //   //   icon: "icon-shezhi",
      //   //   children:[
      //   //     {
      //   //       path: "/courseManagement/courseOrderList",
      //   //       name: "课程订单",
      //   //       icon: "icon-changyongxinxi",
      //   //     },
      //   //     {
      //   //       path: "/courseManagement/lecturer",
      //   //       name: "课程讲师",
      //   //       icon: "icon-changyongxinxi",
      //   //     },
      //   //   ]
      //   // },
      //   {
      //     path: "/account",
      //     name: "账号管理",
      //     icon: "icon-shezhi",
      //     children: [
      //       {
      //         path: "/account/accountList",
      //         name: "账号列表",
      //         icon: "icon-changyongxinxi",
      //       },
      //       // {
      //       //   path:"/account/auth",
      //       //   name:"角色管理",
      //       //   icon: "icon-quanxianguanli",
      //       // },
      //     ]
      //   },
      //   {
      //     path: "/address",
      //     name: "物流管理",
      //     icon: "icon-wangpuzhuangxiu",
      //     children: [
      //       {
      //         path: "/address/addressList",
      //         name: "地址设置",
      //         icon: "icon-zhuti_o",
      //       },
      //       {
      //         path: "/address/freightTemplate",
      //         name: "运费模版",
      //         icon: "icon-zhuti_o",
      //       }
      //     ]
      //   },
      //   {
      //     path: "/setup",
      //     name: "设置",
      //     icon: "icon-shezhi",
      //     children: [
      //       {
      //         path: "/setup/settlementSettings",
      //         name: "结算设置",
      //         icon: "icon-changyongxinxi",
      //       },
      //       {
      //         path: "/setup/revenueSettings",
      //         name: "进账设置",
      //         icon: "icon-changyongxinxi",
      //       },
      //       {
      //         path: "/setup/withdrawalSettings",
      //         name: "提现设置",
      //         icon: "icon-changyongxinxi",
      //       },
      //       {
      //         path: "/setup/parameterSettings",
      //         name: "配置设置",
      //         icon: "icon-changyongxinxi",
      //       }
      //     ]
      //   },
      // ]  //数组
      menuList: [
        {
          path: "/index",
          name: "工作台",
          icon: "icon-shouye",
        },
      ],
      // copyMenuList:JSON.localStorage.getItem("copyMenuList"),
    };
  },
  created() {
    this.activeMenu = localStorage.getItem("activeMenu")
      ? localStorage.getItem("activeMenu")
      : "1";
    this.getConfigs(); //获取配置信息
  },
  mounted() {
    // console.log('执行');
  },
  computed: {
    isCollapse() {
      //展开列表
      return this.$store.state.isCollapse;
    },
    isActiveMenu() {
      //获取vuex 右侧返回首页下表数据
      // console.log("数据vuex",this.$store.state.activeMenu)
      return this.$store.state.activeMenu;
    },
  },
  watch: {
    "$route.path": {
      handler(val, oldVal) {
        if (
          val != "/indexPartner" &&
          val != "/home" &&
          val != "/index" &&
          val != "/courseManagement/courseList"
        ) {
          // console.log(val,11111222221111);
          this.menuList = JSON.parse(localStorage.getItem("copyMenuList"));
          return;
        }
        if (val == "/home") {
          this.menuList = [
            {
              path: "/home",
              name: "电商概览",
              icon: "icon-shouye",
            },
            // {
            //   path: "/merchant",
            //   name: "商户管理",
            //   icon: "icon-shouye",
            //   children: [
            //     {
            //       path: "/merchant/merchantList",
            //       name: "商户列表",
            //       icon: "icon-dingdanliebiao",
            //     },
            //     {
            //       path: "/merchant/storeReview",
            //       name: "商户审核",
            //       icon: "icon-dingdanliebiao",
            //     },
            //     {
            //       path: "/merchant/categories",
            //       name: "主营类目",
            //       icon: "icon-dingdanliebiao",
            //     },
            //   ],
            // },
            {
              path: "/storeManagement",
              name: "门店管理",
              icon: "icon-kehu",
              children: [
                {
                  path: "/storeManagement/shopList",
                  name: "门店列表",
                  icon: "icon-dingdanliebiao",
                },
                {
                  path: "/merchant/categories",
                  name: "主营类目",
                  icon: "icon-dingdanliebiao",
                },
                {
                  path: "/merchant/storeReview",
                  name: "门店审核",
                  icon: "icon-dingdanliebiao",
                },
              ]
            },
            {
              path: "/platformUser",
              name: "用户管理",
              icon: "icon-kehu",
              children: [
                {
                  path: "/platformUser/index",
                  name: "用户列表",
                  icon: "icon-dingdanliebiao",
                },
              ],
            },
            // {
            //   path: "/user",
            //   name: "用户管理",
            //   icon: "icon-kehu",
            //   children: [
            //     {
            //       path: "/user/index",
            //       name: "用户列表",
            //       icon: "icon-dingdanliebiao",
            //     },
            //     {
            //       path: "/user/levelIndex",
            //       name: "等级设置",
            //       icon: "icon-dingdanliebiao",
            //     }
            //   ]
            // },
            {
              path: "/commodity",
              name: "商品管理",
              icon: "icon-31quanbushangpin",
              children: [
                // {
                //   path: "/commodity/cattle",
                //   name: "礼包商品列表",
                //   icon: "icon-shangpinduibi",
                // },
                {
                  path: "/commodity/cattleindex",
                  name: "商品列表",
                  icon: "icon-shangpinduibi",
                },
                {
                  path: "/commodity/redPacketGoodList",
                  name: "红包商品列表",
                  icon: "icon-shangpinduibi",
                },
                {
                  path: "/commodity/shopClass",
                  name: "商品类目",
                  icon: "icon-shangpinduibi",
                },
                {
                  path: "/commodity/paveGoods",
                  name: "铺货",
                  icon: "icon-shangpinduibi",
                },
                {
                  path: "/commodity/auditList",
                  name: "审核列表",
                  icon: "icon-shangpinduibi",
                },
              ],
            },
            {
              path: "/order",
              name: "订单管理",
              icon: "icon-dingdan",
              children: [
                {
                  path: "/order/platformList",
                  name: "平台分销订单",
                  icon: "icon-dingdanliebiao",
                },
                // {
                //   path: "/order/merchantList",
                //   name: "商户分销订单",
                //   icon: "icon-dingdanliebiao",
                // },
                {
                  path: "/order/storeOrder",
                  name: "门店自营订单",
                  icon: "icon-wj-shd",
                },
                {
                  path: "/order/channelList",
                  name: "渠道订单",
                  icon: "icon-wj-shd",
                },
                {
                  path: "/order/redPacketPlatformOrderList",
                  name: "红包商品平台订单",
                  icon: "icon-dingdanliebiao",
                },
                {
                  path: "/order/redPacketChannelOrderList",
                  name: "红包商品渠道订单",
                  icon: "icon-wj-shd",
                },
              ],
            },
            {
              path: "/after",
              name: "售后管理",
              icon: "icon-dingdan",
              children: [
                {
                  path: "/after/afterProvidedOrder",
                  name: "平台分销售后",
                  icon: "icon-dingdanliebiao",
                },
                // {
                //   path: "/after/afterMerchantOrder",
                //   name: "商户分销售后",
                //   icon: "icon-dingdanliebiao",
                // },
                {
                  path: "/after/afterStoreOrder",
                  name: "门店自营售后",
                  icon: "icon-wj-shd",
                },
                {
                  path: "/after/afterChannelOrder",
                  name: "渠道商售后",
                  icon: "icon-wj-shd",
                },
              ],
            },
            {
              path: '/clouStore',
              name: '云仓管理',
              icon: "icon-shezhi",
              children: [
                {
                  path: "/clouStore/goodList",
                  name: "商品管理",
                  icon: "icon-wodeshangpin",
                },
                {
                  path: "/clouStore/cloudSaleSet",
                  name: "销售数量配置",
                  icon: "icon-wodeshangpin",
                },
                {
                  path: "/clouStore/cloudStoreOrderList",
                  name: "云仓订单",
                  icon: "icon-wodeshangpin",
                }
              ]
            },
            {
              path: "/specialSaleAct",
              name: "特卖会管理",
              icon: "icon-shouye",
              children: [
                {
                  path: "/specialSaleAct/specialSaleActivityList",
                  name: "特卖活动管理",
                  icon: "icon-shouye",
                },
                {
                  path: "/specialSaleAct/specailSaleActRuleList",
                  name: "补货规则管理",
                  icon: "icon-shouye",
                },
                {
                  path: "/specialSaleAct/specialSaleOrderList",
                  name: "订单管理",
                  icon: "icon-shouye",
                },
              ],
            },
            {
              path: "/redPacket",
              name: "红包管理",
              icon: "icon-shouye",
              children: [
                {
                  path: "/redPacket/redPacketShopList",
                  name: "门店红包",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacket/userOriginRedPacketList",
                  name: "用户原始红包",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacket/userConsumeRedPacketList",
                  name: "用户消费红包",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacket/userRewardRedPacketList",
                  name: "用户奖励红包",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacket/originRedPacketCopyList",
                  name: "原始红包复刻设置",
                  icon: "icon-shouye",
                },
              ],
            },
            {
              path: "/redPacketReward",
              name: "红包奖励管理",
              icon: "icon-shouye",
              children: [
                {
                  path: "/redPacketReward/setRedPacketAwardConfig",
                  name: "红包奖励配置",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacketReward/shopAwardList",
                  name: "门店奖励",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacketReward/userAwardList",
                  name: "用户奖励",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacketReward/shopCashAuditList",
                  name: "门店提现审核",
                  icon: "icon-shouye",
                },
                {
                  path: "/redPacketReward/userCashAuditList",
                  name: "用户提现审核",
                  icon: "icon-shouye",
                },
              ],
            },
            {
              path: "/marketing",
              name: "营销管理",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/marketing/modelShopList",
                  name: "样板店列表",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/marketing/allowanceList",
                  name: "津贴活动",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/marketing/freeList",
                  name: "免单活动",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/marketing/prizeDrawList",
                  name: "抽奖活动",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/marketing/activityOrderList",
                  name: "活动订单",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
            {
              path: "/live",
              name: "直播管理",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/live/liveSetup",
                  name: "基本设置",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/live/anchorManage",
                  name: "主播管理",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/live/studioManagement",
                  name: "直播间管理",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/live/liveShop",
                  name: "直播商品",
                  icon: "icon-shouye",
                },
                {
                  path: "/live/LiveRecord",
                  name: "直播记录",
                  icon: "icon-shouye",
                },
                {
                  path: "/live/LiveOrder",
                  name: "直播订单",
                  icon: "icon-shouye",
                },
              ],
            },
            {
              path: "/financial",
              name: "财务管理",
              icon: "icon-shezhi",
              children: [
                // {
                //   path: "/financial/bonusStatistics",
                //   name: "分红池统计",
                //   icon: "icon-changyongxinxi",
                // },
                // {
                //   path: "/financial/revenueRecords",
                //   name: "收益转入记录",
                //   icon: "icon-changyongxinxi",
                // },
                // {
                //   path: "/financial/moneyDetails",
                //   name: "佣金明细",
                //   icon: "icon-changyongxinxi",
                // },
                // {
                //   path: "/financial/balance",
                //   name: "储蓄金管理",
                //   icon: "icon-changyongxinxi",
                //   children: [
                //     {
                //       path: "/financial/balance/rechargeRecords",
                //       name: "充值记录",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/balance/merchantBalance",
                //       name: "商户余额",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/balance/balanceReview",
                //       name: "余额提现审核",
                //       icon: "icon-changyongxinxi",
                //     },
                //   ],
                // },
                // {
                //   path: "/financial/merchantFx",
                //   name: "商户分销款",
                //   icon: "icon-changyongxinxi",
                //   children: [
                //     {
                //       path: "/financial/merchantFx/fxList",
                //       name: "分销列表",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/merchantFx/fxDetail",
                //       name: "分销款明细",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/merchantFx/fxStatement",
                //       name: "分销对账单",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/merchantFx/fxWithdrawalReview",
                //       name: "分销提现审核",
                //       icon: "icon-changyongxinxi",
                //     },
                //   ],
                // },
                {
                  path: "/financial/storeFx",
                  name: "门店分销款",
                  icon: "icon-changyongxinxi",
                  children: [
                    {
                      path: "/financial/storeFx/fxList",
                      name: "分销列表",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/storeFx/fxDetail",
                      name: "分销款明细",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/storeFx/fxStatement",
                      name: "分销对账单",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/storeFx/fxWithdrawalReview",
                      name: "分销提现审核",
                      icon: "icon-changyongxinxi",
                    },
                  ],
                },
                // {
                //   path: "/financial/money",
                //   name: "采购金管理",
                //   icon: "icon-changyongxinxi",
                //   children: [
                //     {
                //       path: "/financial/money/merchantMoney",
                //       name: "商户采购金",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/money/moneyRecords",
                //       name: "充值记录",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/money/moneyReview",
                //       name: "采购金提现审核",
                //       icon: "icon-changyongxinxi",
                //     },
                //   ],
                // },
                // {
                //   path: "/financial/merchantPayment",
                //   name: "商户货款",
                //   icon: "icon-changyongxinxi",
                //   children: [
                //     {
                //       path: "/financial/merchantPayment/paymentDetails",
                //       name: "货款明细",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/merchantPayment/statement",
                //       name: "对账单",
                //       icon: "icon-changyongxinxi",
                //     },
                //     {
                //       path: "/financial/merchantPayment/paymentReview",
                //       name: "货款提现审核",
                //       icon: "icon-changyongxinxi",
                //     },
                //   ],
                // },
                {
                  path: "/financial/storePayment",
                  name: "门店货款",
                  icon: "icon-changyongxinxi",
                  children: [
                    {
                      path: "/financial/storePayment/paymentDetails",
                      name: "货款明细",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/storePayment/statement",
                      name: "对账单",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/storePayment/paymentReview",
                      name: "货款提现审核",
                      icon: "icon-changyongxinxi",
                    },
                  ],
                },
                {
                  path: "/financial/platPayment",
                  name: "平台货款",
                  icon: "icon-changyongxinxi",
                  children: [
                    {
                      path: "/financial/platPayment/paymentDetails",
                      name: "货款明细",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/platPayment/statement",
                      name: "对账单",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/platPayment/payment",
                      name: "货款管理",
                      icon: "icon-changyongxinxi",
                    },
                  ],
                },
                {
                  path: "/financial/serviceFee",
                  name: "手续费",
                  icon: "icon-changyongxinxi",
                  children: [
                    {
                      path: "/financial/serviceFee/serviceFeeManage",
                      name: "手续费管理",
                      icon: "icon-changyongxinxi",
                    },
                    {
                      path: "/financial/serviceFee/serviceFeeRecord",
                      name: "手续费记录",
                      icon: "icon-changyongxinxi",
                    },
                  ],
                },
              ],
            },
            // {
            //   path: "/courseManagement",
            //   name: "课程管理",
            //   icon: "icon-shezhi",
            //   children:[
            //     {
            //       path: "/courseManagement/courseOrderList",
            //       name: "课程订单",
            //       icon: "icon-changyongxinxi",
            //     },
            //     {
            //       path: "/courseManagement/lecturer",
            //       name: "课程讲师",
            //       icon: "icon-changyongxinxi",
            //     },
            //   ]
            // },

            {
              path: "/address",
              name: "物流管理",
              icon: "icon-wangpuzhuangxiu",
              children: [
                {
                  path: "/address/addressList",
                  name: "地址设置",
                  icon: "icon-zhuti_o",
                },
                {
                  path: "/address/freightTemplate",
                  name: "运费模版",
                  icon: "icon-zhuti_o",
                },
              ],
            },
            {
              path: "/setup",
              name: "设置",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/setup/platformOrderSettings",
                  name: "平台订单设置",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/setup/platformAfterSettings",
                  name: "平台售后设置",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/setup/dkSettlementSettings",
                  name: "货款结算设置",
                  icon: "icon-changyongxinxi",
                },
                // {
                //   path: "/setup/shopSettlementSettings",
                //   name: "商品分销设置",
                //   icon: "icon-changyongxinxi",
                // },
                {
                  path: "/setup/fxWithdrawalSettings",
                  name: "提现规则",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/setup/serviceSettings",
                  name: "客服设置",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/setup/supplySetH5",
                  name: "H5供应商展示设置",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
          ]; //数组
          localStorage.setItem("copyMenuList", JSON.stringify(this.menuList));
        } else if (val == "/indexPartner") {
          this.menuList = [
            {
              path: "/indexPartner",
              name: "合伙人概览",
              icon: "icon-shouye",
            },
            // {
            //   path: "/merchant",
            //   name: "商户管理",
            //   icon: "icon-shouye",
            //   children: [
            //     {
            //       path: "/merchant/storeReview",
            //       name: "商户审核",
            //       icon: "icon-dingdanliebiao",
            //     },
            //     {
            //       path: "/merchant/categories",
            //       name: "主营类目",
            //       icon: "icon-dingdanliebiao",
            //     }
            //   ]
            // },
            {
              path: "/user",
              name: "用户管理",
              icon: "icon-kehu",
              children: [
                {
                  path: "/user/index",
                  name: "用户列表",
                  icon: "icon-dingdanliebiao",
                },
                {
                  path: "/user/levelIndex",
                  name: "等级设置",
                  icon: "icon-dingdanliebiao",
                },
              ],
            },
            {
              path: "/commodity",
              name: "商品管理",
              icon: "icon-31quanbushangpin",
              children: [
                {
                  path: "/commodity/cattle",
                  name: "礼包商品列表",
                  icon: "icon-shangpinduibi",
                },
                {
                  path: "/commodity/cattleCombined",
                  name: "组合商品列表",
                  icon: "icon-shangpinduibi",
                },
                // {
                //   path: "/commodity/cattleindex",
                //   name: "商品列表",
                //   icon: "icon-shangpinduibi",
                // },
                // {
                //   path: "/commodity/shopClass",
                //   name: "商品类目",
                //   icon: "icon-shangpinduibi",
                // }
              ],
            },
            {
              path: "/order",
              name: "订单管理",
              icon: "icon-dingdan",
              children: [
                // {
                //   path: "/order/platformList",
                //   name: "平台分销订单",
                //   icon: "icon-dingdanliebiao",
                // },
                // {
                //   path: "/order/merchantList",
                //   name: "商户分销订单",
                //   icon: "icon-dingdanliebiao",
                // },
                // {
                //   path: "/order/storeOrder",
                //   name: "门店自营订单",
                //   icon: "icon-wj-shd",
                // },
                {
                  path: "/order/orderList",
                  name: "合伙人订单",
                  icon: "icon-wj-shd",
                },
                {
                  path: "/order/orderCombinedList",
                  name: "组合商品订单",
                  icon: "icon-wj-shd",
                },
              ],
            },
            {
              path: "/resource",
              name: "资源包管理",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/resource/resourceList",
                  name: "资源包列表",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
            // {
            //   path: "/after",
            //   name: "售后管理",
            //   icon: "icon-dingdan",
            //   children: [
            //     {
            //       path: "/after/afterProvidedOrder",
            //       name: "平台分销订单",
            //       icon: "icon-dingdanliebiao",
            //     },
            //     {
            //       path: "/after/afterMerchantOrder",
            //       name: "商户分销订单",
            //       icon: "icon-dingdanliebiao",
            //     },
            //     {
            //       path: "/after/afterStoreOrder",
            //       name: "门店自营订单",
            //       icon: "icon-wj-shd",
            //     }
            //   ]
            // },
            {
              path: "/financial",
              name: "财务管理",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/financial/bonusStatistics",
                  name: "分红池统计",
                  icon: "icon-changyongxinxi",
                },
                // {
                //   path: "/financial/revenueRecords",
                //   name: "收益转入记录",
                //   icon: "icon-changyongxinxi",
                // },
                {
                  path: "/financial/moneyDetails",
                  name: "佣金明细",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/financial/withdrawalReview",
                  name: "提现审核",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
            // {
            //   path: "/courseManagement",
            //   name: "课程管理",
            //   icon: "icon-shezhi",
            //   children:[
            //     {
            //       path: "/courseManagement/courseOrderList",
            //       name: "课程订单",
            //       icon: "icon-changyongxinxi",
            //     },
            //     {
            //       path: "/courseManagement/lecturer",
            //       name: "课程讲师",
            //       icon: "icon-changyongxinxi",
            //     },
            //   ]
            // },
            // {
            //   path: "/account",
            //   name: "账号管理",
            //   icon: "icon-shezhi",
            //   children: [
            //     {
            //       path: "/account/accountList",
            //       name: "账号列表",
            //       icon: "icon-changyongxinxi",
            //     },
            //     // {
            //     //   path:"/account/auth",
            //     //   name:"角色管理",
            //     //   icon: "icon-quanxianguanli",
            //     // },
            //   ]
            // },
            // {
            //   path: "/address",
            //   name: "物流管理",
            //   icon: "icon-wangpuzhuangxiu",
            //   children: [
            //     {
            //       path: "/address/addressList",
            //       name: "地址设置",
            //       icon: "icon-zhuti_o",
            //     },
            //     {
            //       path: "/address/freightTemplate",
            //       name: "运费模版",
            //       icon: "icon-zhuti_o",
            //     }
            //   ]
            // },
            {
              path: "/setup",
              name: "设置",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/setup/settlementSettings",
                  name: "结算设置",
                  icon: "icon-changyongxinxi",
                },
                // {
                //   path: "/setup/revenueSettings",
                //   name: "进账设置",
                //   icon: "icon-changyongxinxi",
                // },
                {
                  path: "/setup/withdrawalSettings",
                  name: "提现设置",
                  icon: "icon-changyongxinxi",
                },
                // {
                //   path: "/setup/parameterSettings",
                //   name: "配置设置",
                //   icon: "icon-changyongxinxi",
                // },
                {
                  path: "/setup/serviceSettingsH5",
                  name: "客服设置",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
          ]; //数组
          localStorage.setItem("copyMenuList", JSON.stringify(this.menuList));
        } else if (val == "/index") {
          this.menuList = [
            {
              path: "/index",
              name: "工作台",
              icon: "icon-shouye",
            },
            {
              path: "/account",
              name: "账号管理",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/account/accountList",
                  name: "账号列表",
                  icon: "icon-changyongxinxi",
                },
                // {
                //   path:"/account/auth",
                //   name:"角色管理",
                //   icon: "icon-quanxianguanli",
                // },
              ],
            },
            {
              path: "/pageSetup",
              name: "系统页面配置",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/pageSetup/platFormConfig",
                  name: "平台页面配置",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/pageSetup/merchantConfig",
                  name: "商户页面配置",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/pageSetup/storeConfig",
                  name: "门店页面配置",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
          ];
          localStorage.setItem("copyMenuList", JSON.stringify(this.menuList));
        } else if (val == "/courseManagement/courseList") {
          this.menuList = [
            {
              path: "/courseManagement",
              name: "课程管理",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/courseManagement/courseList",
                  name: "课程列表",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/courseManagement/lecturer",
                  name: "课程讲师",
                  icon: "icon-changyongxinxi",
                },
                {
                  path: "/courseManagement/courseCategories",
                  name: "课程类目",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
            {
              path: "/courseOrder",
              name: "课程订单",
              icon: "icon-shezhi",
              children: [
                {
                  path: "/courseOrder/courseOrderList",
                  name: "订单列表",
                  icon: "icon-changyongxinxi",
                },
              ],
            },
          ];
          localStorage.setItem("copyMenuList", JSON.stringify(this.menuList));
        }
      },
      immediate: true, // 第一次改变就执行
    },
  },
  methods: {
    getConfigs() {
      //获取配置信息
      getConfig({ key: "platform_page_configuration" })
        .then((res) => {
          this.content = res.content;
        })
        .catch((err) => {});
    },
    toSubMenu(path, item, index) {
      // console.log("open11111", path, item, index);
      this.activeMenu = String(index);
      localStorage.setItem("activeMenu", this.activeMenu);
      if (!item.children) {
        this.$router.push({ path: path });
      }
    },
    toPage(path, item, idx) {
      // console.log("open22222",path, item,idx);
      this.activeMenu = idx;
      localStorage.setItem("activeMenu", this.activeMenu);
      this.$router.push({ path: path });
    },
    handleOpen(key, keyPath) {
      // console.log("open",key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log("close",key, keyPath);
    },
  },
};
</script>

<style lang="less" scoped>
.el-aside {
  width: 206px !important;
  background: #d8e4fb !important;
  height: 100vh;

  .logo {
    height: 80px;
    font-size: 20px;
    color: #639aff;
    text-align: center;
    // padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #639aff !important;

    img {
      width: 180px;
      height: 60px;
    }
  }
}

/deep/.el-submenu__title {
  text-align: center;

  span {
    // font-weight: bold;
    font-size: 16px;
  }
}

/deep/.el-menu-item {
  text-align: center;
  height: 53px;

  span {
    // font-weight: bold;
    font-size: 16px;
  }
}

/deep/.el-menu-item.is-active {
  height: 53px;
  text-align: center;
  font-size: 14px;
  background-color: #639aff !important;
}

/deep/.el-menu-item-group__title {
  padding: 0;
}

/deep/.el-submenu__icon-arrow {
  font-size: 16px;
}

/deep/.el-submenu__title:hover {
  color: #fff !important;
  background: #639aff !important;

  i {
    color: #fff;
  }
}

/deep/.el-menu-item-group {
  .el-menu-item {
    height: 40px;
    color: #999999 !important;
    line-height: 40px;
    padding: 0 !important;
    text-align: center;

    span {
      width: 3px;
      height: 14px;
      display: inline-block;
      margin-right: 10px;
      background: #d8e4fb !important;
      border-radius: 5px;
      font-weight: bold;
    }
  }

  .el-menu-item:hover {
    color: #fff !important;
    background: #639aff !important;
  }

  .is-active {
    color: #fff !important;
    background: #639aff !important;

    span {
      width: 3px;
      height: 14px;
      display: inline-block;
      margin-right: 10px;
      background: #639aff;
      border-radius: 5px;
    }
  }

  .is-active:hover {
    color: #fff !important;
    background: #639aff !important;
  }
}

.el-aside_on {
  width: 64px !important;
}

.el-menu {
  border-right: none;
}

.el-main {
  padding: 0;
  color: #333;
  background: #f7f8fa;
  // padding: 10px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  // text-align: center;
  // line-height: 160px;
}

.el-header {
  height: 80px !important;
  color: #333;
  line-height: 80px;
  padding: 0;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: #fff;
}
</style>