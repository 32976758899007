import { request } from '@/api/request';

//平台登录
export const platformAdminLogin = data => {
	return request('post','/api/Login/PlatformAdmin/login',data);
}

//平台合伙人用户列表
export const getPartnerList = data => {
	return request('get','/api/Account/PlatformPartner/getPartnerList',data);
}

//平台合伙人详情
export const getPartnerDetail = data => {
	return request('get','/api/Account/PlatformPartner/getPartnerDetail',data);
}

//获取合伙人等级列表
export const partnerLevelList = data => {
	return request('get','/api/Account/PlatformPartner/partnerLevelList',data);
}

//获取合伙人等级详情
export const partnerLevelDetail = data => {
	return request('get','/api/Account/PlatformPartner/partnerLevelDetail',data);
}

//更新或新增等级
export const createOrUpdateLevelInfo = data => {
	return request('post','/api/Account/PlatformPartner/createOrUpdateLevelInfo',data);
}

//修改合伙人等级
export const modifyMerchantLevel = data => {
	return request('post','/api/Account/PlatformPartner/modifyMerchantLevel',data);
}

//删除合伙人等级
export const deleteLevelData = data => {
	return request('post','/api/Account/PlatformPartner/deleteLevelData',data);
}

//获取合伙人上级链路
export const getParentLink = data => {
	return request('get','/api/Account/PlatformPartner/getParentLink',data);
}

//修改合伙人分红额度
export const modifyMerchantCommissionTotal = data => {
	return request('post','/api/Account/PlatformPartner/modifyMerchantCommissionTotal',data);
}

//修改合伙人上级
export const modifyMerchantParent = data => {
	return request('post','/api/Account/PlatformPartner/modifyMerchantParent',data);
}

//获取合伙人下级信息
export const getMerchantSubInfo = data => {
	return request('get','/api/Account/PlatformPartner/getMerchantSubInfo',data);
}


//商户用户消费信息
export const consumptionInfo = data => {
	return request('get','/api/Account/MerchantUser/consumptionInfo',data);
}

//商户用户充值
export const MerchantUserrecharge = data => {
	return request('post','/api/Account/MerchantUser/recharge',data);
}

//商户用户资金明细
export const accountCapitalLog = data => {
	return request('get','/api/Account/MerchantUser/accountCapitalLog',data);
}

//商户用户保存标签
export const saveTags = data => {
	return request('post','/api/Account/MerchantUser/saveTags',data);
}

//商户等级列表
export const levelList = data => {
	return request('get','/api/Account/MerchantUser/levelList',data);
}

//商户等级详情
export const levelDetail = data => {
	return request('get','/api/Account/MerchantUser/levelDetail',data);
}

//商户等级编辑
export const levelModify = data => {
	return request('post','/api/Account/MerchantUser/levelModify',data);
}

//添加合伙人
export const createPartner = data => {
	return request('post','/api/Account/PlatformPartner/createPartner',data);
}

//概览
export const dataindex = data => {
	return request('get','/api/data/index',data);
}

//平台用户列表
export const PlatformUser = data => {
	return request('get','/api/Account/PlatformUser/list',data);
}

//平台商户列表
export const getMerchantList = data => {
	return request('get','/api/Account/PlatformUser/getMerchantList',data);
}

//平台用户详情
export const PlatformUserDetail = data => {
	return request('get','/api/Account/PlatformUser/detail',data);
}

//平台用户消费信息
export const getConsumptionInfo = data => {
	return request('get','/api/Account/PlatformUser/consumptionInfo',data);
}

//平台用户消费信息
export const getAccountCapitalLog = data => {
	return request('get','/api/Account/PlatformUser/accountCapitalLog',data);
}

//添加修改系统配置
export const saveConfig = data => {
	return request('post','/api/Settings/system/saveConfig',data);
}

//获取系统配置
export const getConfig = data => {
	return request('get','/api/Settings/system/getConfig',data);
}
